html, body {
    height: 100%;
}

body {
    background-color: white;
    margin: 0;
    padding: 0;
}

.f-application-root {
    height: 100%;
    overflow: hidden;
}

.no-content {
    color: #0006;
    font-size: 1.2em;
    font-style: italic;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.3);
}